import React from "react";
import { Redirect } from "react-router-dom";
import { useOktaAuth } from "@okta/okta-react";
import OktaSignInWidget from "./OktaSignInWidget";
import "./Login.scss";
import QueryStringService from "./QueryStringService";
import { Col, Row } from "react-bootstrap";

const Login = ({ config }) => {
  const { oktaAuth, authState } = useOktaAuth();
  const { getValue } = QueryStringService();

  const isVendor = getValue("vendor") === "1" ? true : false;
  const otp = getValue("otp");
  const state = getValue("state");

  const widgetConfig = {
    ...config,
    otp,
    state,
  };

  const onSuccess = (tokens) => {
    oktaAuth.handleLoginRedirect(tokens);
  };

  const onError = (err) => {
    console.log("Sign in error:", err);
  };

  if (!authState) {
    return <div>Loading ... </div>;
  }

  const appStoreItems = [
    {
      link: "https://apps.apple.com/us/app/accrualify-app/id1205390661",
      image: "/assets/images/app_store.png",
    },
    {
      link: "https://play.google.com/store/apps/details?id=com.accrualify.app",
      image: "/assets/images/google_play.png",
    },
  ];

  console.log("Login Client Tokens: ", oktaAuth.tokenManager.getTokensSync())

  console.log("Login authState Tokens: ", authState.accessToken, authState.idToken);
  console.log("Login AuthState Error: ", authState.error);

  oktaAuth.session.get().then((res) => console.log("Existing Login Session: ", res))
  .catch((err) => console.log("Existing Session Error: ", err));

  return authState.isAuthenticated ? (
    <Redirect to={{ pathname: "/", search: window.location.search }} />
  ) : (
    <Row className="view-container">
      <Col md = {5} className="login-container">
        <OktaSignInWidget
          config={widgetConfig}
          onSuccess={onSuccess}
          onError={onError}
          isPasswordReset={otp && state}
        />
        {!isVendor && (
          <div>
            <div className="app-store-container">
              <div className="title">Available on</div>
              <div className="items-container">
                {appStoreItems.map((item, index) => (
                  <div className="item-container" key={index}>
                    <a href={item.link} target="_blank" rel="noreferrer">
                      <img
                        src={item.image}
                        alt="Available on"
                        className="app-store-image"
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </Col>
      {isVendor && (
        <Col md = {7} className="content-container-vendor"></Col>
      )}
      {!isVendor && (
        <Col
          md = {7}
          className="content-container"
          onClick={() =>
            (window.location.href =
              "https://www.accrualify.com/company/contact-us")
          }
        ></Col>
      )}
    </Row>
  );
};

export default Login;
