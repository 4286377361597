import queryString from "query-string";

const QueryStringService = () => {
  const getValue = (key) => {
    const search = window.location.search;
    const queryStringValues = queryString.parse(search);

    return queryStringValues[key];
  };

  return {
    getValue,
  };
};

export default QueryStringService;
