import React, { useEffect, useState } from "react";
import { useOktaAuth } from "@okta/okta-react";
import config from "./config";
import Loading from "./Loading"
import QueryStringService from "./QueryStringService";
import "./Logout.scss";

const Logout = () => {
  const { oktaAuth } = useOktaAuth();
  const { getValue } = QueryStringService();
  const [sessionClosed, setSessionClosed] = useState(false);
  const [logoutCallbacks, setLogoutCallbacks] = useState([]);

  const isVendor = getValue("vendor") === "1" ? true : false;
  const killSessionAsync = async () => {

    const tokensToBeCleared = oktaAuth.tokenManager.getTokensSync();
    const session = await oktaAuth.session.get();
    console.log("Logout Session to be closed: ", session)
    console.log("Logout Client Tokens: ", tokensToBeCleared);
    const revokeAccess = await oktaAuth.revokeAccessToken();
    const revokeRefresh = await oktaAuth.revokeRefreshToken();
    const closeSession = await oktaAuth.closeSession();
    console.log('Okta Revoke Access?: ', revokeAccess);
    console.log('Okta Revoke Refresh?: ', revokeRefresh);
    console.log('Okta Close Session?: ', closeSession);

    setSessionClosed(true);
    console.log("Okta KillSession Finished");
  };

  const signOutOfOkta = async () => {
    await killSessionAsync(); //make sure we kill session before setting up iframes
    const callbacks = config.logoutCallbackUrls.map((item, index) => {
      const name = item.split("|")[0];
      const url = item.split("|")[1];

      return { name, url, status: "pending" };
    });
    setLogoutCallbacks(callbacks);
  };

  useEffect(() => {
    signOutOfOkta();
  }, [oktaAuth]);

  useEffect(() => {
    if (
      logoutCallbacks.length > 0 &&
      logoutCallbacks.every((cb) => cb.status === "complete")
    ) {
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        if (isVendor) {
          window.location.replace("/login?vendor=1");
        } else {
          console.log("Okta Starting Redirect to Login")
          window.location.replace("/login");
        }
      }, 2000);
    }
  }, [isVendor, logoutCallbacks]);

  const completeCallback = (item) => {
    setLogoutCallbacks(
      logoutCallbacks.map((cb) => {
        return cb.name === item.name ? { ...cb, status: "complete" } : cb;
      })
    );
  };

  return (
    <>
      <Loading caption="Signing out of Corpay Complete..."/>
      <div style={{ visibility: "hidden" }}>
        {sessionClosed &&
          logoutCallbacks.map((item, index) => {
            return (
              <iframe
                onLoad={() => completeCallback(item)}
                src={item.url}
                height="0"
                width="0"
                title="logout"
                key={index}
              ></iframe>
            );
          })}
      </div>
    </>
  );
};

export default Logout;