import React, { useEffect, useRef } from "react";
import OktaSignIn from "@okta/okta-signin-widget";
import "@okta/okta-signin-widget/css/okta-sign-in.min.css";

import "./OktaSignInWidget.scss";

const OktaSignInWidget = ({
  config,
  onSuccess,
  onError,
  isPasswordReset,
}) => {
  const widgetRef = useRef();
  useEffect(() => {
    if (!widgetRef.current) {
      return false;
    }

    const widget = new OktaSignIn(config);

    if (isPasswordReset) {
      widget
        .showSignInAndRedirect({
          el: widgetRef.current,
        })
        .then(onSuccess)
        .catch(onError);
    } else {
      widget
        .showSignInToGetTokens({
          el: widgetRef.current,
        })
        .then(onSuccess)
        .catch(onError);
    }

    return () => widget.remove();
  }, [onSuccess, onError, isPasswordReset, config]);

  return <div ref={widgetRef} />;
};

export default OktaSignInWidget;
