const clientId = process.env.REACT_APP_OKTA_CLIENT_ID;
const domain = process.env.REACT_APP_OKTA_DOMAIN;
const issuer = process.env.REACT_APP_OKTA_ISSUER;
const redirectUri = `${window.location.origin}${process.env.REACT_APP_OKTA_REDIRECT_URI}`;
const scopes = JSON.parse(process.env.REACT_APP_OKTA_SCOPES);
const logoutUrls = JSON.parse(process.env.REACT_APP_OKTA_LOGOUT_URLS);

const config = {
  oidc: {
    clientId,
    domain,
    issuer,
    redirectUri,
    scopes,
  },
  widget: {
    clientId,
    issuer,
    redirectUri,
    scopes,
    logo: "/assets/images/Corpay_Logo.svg",
    colors: {
      brand: "#00809F",
    },
  },
  logoutCallbackUrls: logoutUrls,
};

export default config;
