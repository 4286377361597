import React from "react";
import { useOktaAuth } from "@okta/okta-react";
import QueryStringService from "./QueryStringService";

const Home = () => {
  const { authState } = useOktaAuth();
  const { getValue } = QueryStringService();

  const isVendor = getValue("vendor") === "1" ? true : false;
  const redirectUrl = getValue("redirectUrl");

  if (authState.isAuthenticated && redirectUrl) {
    window.location.replace(redirectUrl);
  } else if (authState.isAuthenticated && isVendor) {
    window.location.replace(
      process.env.REACT_APP_OKTA_REDIRECT_LOGGEDIN_VENDOR_URL
    );
  } else if (authState.isAuthenticated) {
    window.location.replace(process.env.REACT_APP_OKTA_REDIRECT_LOGGEDIN_URL);
  }

  return authState.isAuthenticated && redirectUrl ? (
    <div>Redirecting to {redirectUrl}</div>
  ) : (
    <></>
  );
};

export default Home;
