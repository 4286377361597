import { useEffect, useState } from "react";
import config from "./config";
import "./ChangePassword.scss";

const ChangePassword = () => {
  const [formValid, setFormValid] = useState(false);

  const [passwords, setPasswords] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  useEffect(() => {
    const evaluateRules = () => {
      const currentPassword = passwords.currentPassword;
      const newPassword = passwords.newPassword;
      const confirmNewPassword = passwords.confirmNewPassword;

      if (
        !currentPassword ||
        currentPassword.length === 0 ||
        !newPassword ||
        newPassword.length === 0 ||
        !confirmNewPassword ||
        confirmNewPassword.length === 0 ||
        newPassword !== confirmNewPassword
      ) {
        setFormValid(false);
      } else {
        setFormValid(true);
      }
    };

    evaluateRules();
  }, [passwords]);

  const handleOnChange = (event) => {
    const target = event.target;
    const value = target.type === "checkbox" ? target.checked : target.value;
    const name = target.name;

    setPasswords({
      ...passwords,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    const response = await fetch(
      `${config.oidc.domain}/idp/myaccount/password`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json; okta-version=1.0.0",
        },
        mode: "cors",
        body: JSON.stringify(passwords),
      }
    );

    console.log(response);
  };

  return (
    <div className="content-container">
      <div className="password-container">
        <div className="logo-container">
          <img src={config.widget.logo} alt="" />
        </div>
        <div className="input-container">
          {[
            { labelText: "Current Password", name: "currentPassword" },
            { labelText: "New Password", name: "newPassword" },
            { labelText: "Confirm New Password", name: "confirmNewPassword" },
          ].map((item, index) => (
            <div className="input-item-container" key={index}>
              <label>{item.labelText}</label>
              <input
                type="password"
                name={item.name}
                value={passwords[item.name] || ""}
                onChange={handleOnChange}
              />
            </div>
          ))}
        </div>
        <div className="buttons-container">
          <button onClick={handleSubmit} disabled={!formValid}>
            Change Password
          </button>
          <button>Cancel</button>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
