import "./Loading.scss";
const Loading =  ({caption}) =>
(
  <div className = "loading-container">
    <div className="loading-card">
        <div>
            <img src = '/assets/images/loading.gif' alt = "Loading..."></img>
        </div>
        <div>
            <span>{caption}</span>
        </div>
    </div>

  </div>
);

export default Loading;